import React, { useState, useRef } from "react";

import { Formik, Form } from "formik";
import * as Yup from "yup";
import { Modal } from "antd";
import { Button } from "react-bootstrap";
import { toast } from "react-toastify";

import AntdSpinner from "../../../../../components/Loader/AntdSpinner";
import Api from "../../../../../components/helper/ApiUtils";

const ChangePasswordModal = (props) => {
  const formRef = useRef();

  const [loader, setLoader] = useState(false);

  const onChangePassword = (values) => {
    const user = JSON.parse(localStorage.getItem("user"));
    let payload = {
      oldPassword: values.oldPassword,
      newPassword: values.newPassword,
      // confirmPassword: values.confirmPassword,
    };

    setLoader(true);
    Api.changePassword(payload)
      .then((res) => {
        if (res.status === 200) {
          formRef.current.resetForm();
          setLoader(false);
          props.setChangePasswordModal(false);
          toast.success("Password changed successfully");
        }
      })
      .catch((err) => {
        setLoader(false);
        toast.error(err.message);
      });
  };

  return (
    <React.Fragment>
      {loader ? <AntdSpinner /> : ""}
      <Modal
        title="Change Password"
        visible={props.changePasswordModal}
        // onOk={onChangePassword}
        onCancel={() => {
          props.setChangePasswordModal(false);
          formRef.current.resetForm();
        }}
        maskClosable={false}
        width={700}
        footer={[
          <Button
            key="back"
            onClick={() => {
              props.setChangePasswordModal(false);
              formRef.current.resetForm();
            }}
          >
            Cancel
          </Button>,
          <Button
            type="submit"
            variant="danger"
            // loading={loader}
            onClick={() => formRef.current.submitForm()}
          >
            Change Password
          </Button>,
        ]}
      >
        <Formik
          initialValues={{
            oldPassword: "",
            newPassword: "",
            confirmPassword: "",
            viewPasswords: {
              oldPassword: false,
              newPassword: false,
              confirmPassword: false,
            },
          }}
          enableReinitialize={true}
          validationSchema={Yup.object().shape({
            oldPassword: Yup.string()
              .min(8, "Minimum length should be 8")
              .max(30, "Maximum length should be 30")
              .required("Current Password is required"),
            newPassword: Yup.string()
              .min(8, "Minimum length should be 8")
              .max(30, "Maximum length should be 30")
              .required("New Password is required"),
            confirmPassword: Yup.string()
              .min(8, "Minimum length should be 8")
              .max(30, "Maximum length should be 30")
              .oneOf(
                [Yup.ref("newPassword"), null],
                "New Password & Confirm Password must match"
              )
              .required("Confirm Password is required"),
          })}
          innerRef={formRef}
          onSubmit={onChangePassword}
        >
          {({
            setValues,
            handleBlur,
            handleChange,
            touched,
            values,
            errors,
            setFieldValue,
            setFieldTouched,
            resetForm,
            submitForm,
          }) => (
            <Form>
              <div className="form-group position-relative">
                <label className="col-md-4">Current Password: </label>
                <input
                  type={
                    values?.viewPasswords?.oldPassword ? "text" : "password"
                  }
                  className="col-md-8 form-control inputlable"
                  name="oldPassword"
                  placeholder="Enter Current Password"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  error={touched.oldPassword && errors.oldPassword}
                  value={values.oldPassword}
                />
                <i
                  style={{
                    position: "absolute",
                    right: "20px",
                    top: "13px",
                  }}
                  onClick={() => {
                    setFieldValue(
                      "viewPasswords.oldPassword",
                      !values?.viewPasswords?.oldPassword
                    );
                  }}
                  className={
                    values?.viewPasswords?.oldPassword
                      ? "feather icon-eye"
                      : "feather icon-eye-off"
                  }
                />
                {touched.oldPassword && errors.oldPassword && (
                  <small className="ml-auto col-md-8 text-danger form-text">
                    {errors.oldPassword}
                  </small>
                )}
              </div>
              <div className="form-group mb-3 position-relative">
                <label className="col-md-4">New Password: </label>
                <input
                  type={
                    values?.viewPasswords?.newPassword ? "text" : "password"
                  }
                  className="col-md-8 form-control inputlable"
                  name="newPassword"
                  placeholder="Enter New Password"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  error={touched.newPassword && errors.newPassword}
                  value={values.newPassword}
                />
                <i
                  style={{
                    position: "absolute",
                    right: "20px",
                    top: "13px",
                  }}
                  onClick={() => {
                    setFieldValue(
                      "viewPasswords.newPassword",
                      !values?.viewPasswords?.newPassword
                    );
                  }}
                  className={
                    values?.viewPasswords?.newPassword
                      ? "feather icon-eye"
                      : "feather icon-eye-off"
                  }
                />
                {touched.newPassword && errors.newPassword && (
                  <small className="ml-auto col-md-8 text-danger form-text">
                    {errors.newPassword}
                  </small>
                )}
              </div>
              <div className="form-group position-relative">
                <label className="col-md-4">Confirm Password: </label>
                <input
                  type={
                    values?.viewPasswords?.confirmPassword ? "text" : "password"
                  }
                  className="col-md-8 form-control inputlable"
                  name="confirmPassword"
                  placeholder="Enter Confirm Password"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  error={touched.confirmPassword && errors.confirmPassword}
                  value={values.confirmPassword}
                />
                <i
                  style={{
                    position: "absolute",
                    right: "20px",
                    top: "13px",
                  }}
                  onClick={() => {
                    setFieldValue(
                      "viewPasswords.confirmPassword",
                      !values?.viewPasswords?.confirmPassword
                    );
                  }}
                  className={
                    values?.viewPasswords?.confirmPassword
                      ? "feather icon-eye"
                      : "feather icon-eye-off"
                  }
                />
                {touched.confirmPassword && errors.confirmPassword && (
                  <small className="ml-auto col-md-8 text-danger form-text">
                    {errors.confirmPassword}
                  </small>
                )}
              </div>
            </Form>
          )}
        </Formik>
      </Modal>
    </React.Fragment>
  );
};

export default ChangePasswordModal;
