// import axios from 'axios'
import LocalStorage from "./LocalStorage";
import axiosServices from "../../utils/axios";

const API_URL = process.env.REACT_APP_BACKEND_URL
// const API_URL = "http://localhost:4000/api/v1/";

const ApiUtils = {
  login: function (payload) {
    const headers = {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    };
    return axiosServices
      .post(API_URL + "user/signin", payload, {
        headers: headers,
      })
      .then((response) => {
        if (response) {
          return response;
        }
      })
      .catch((error) => {
        if (error && error.response && error.response.data)
          throw error.response.data;
      });
  },

  changePassword: async function (payload) {
    let TOKEN;
    await LocalStorage.getItem("dad_token").then(function (result) {
      TOKEN = result;
    });
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${TOKEN}`,
    };
    return axiosServices
      .post(API_URL + "user/change-password", payload, {
        headers: headers,
      })
      .then((response) => {
        if (response) {
          return response;
        }
      })
      .catch((error) => {
        if (error && error.response && error.response.data)
          throw error.response.data;
      });
  },

  // Edit Profile
  editAdminProfile: async function (payload) {
    let TOKEN;
    await LocalStorage.getItem("dad_token").then(function (result) {
      TOKEN = result;
    });
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${TOKEN}`,
    };
    return axiosServices
      .patch(API_URL + `user/update-profile`, payload, {
        headers: headers,
      })
      .then((response) => {
        if (response && response.data) {
          return response.data;
        }
      })
      .catch((error) => {
        if (error && error.response && error.response.data)
          throw error.response.data;
      });
  },

  // Dashboard data
  getDashboardData: async function (payload) {
    let TOKEN;
    await LocalStorage.getItem("dad_token").then(function (result) {
      TOKEN = result;
    });
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${TOKEN}`,
    };
    return axiosServices
      .get(API_URL + "cms/dashboard", {
        headers: headers,
      })
      .then((response) => {
        if (response && response.data) {
          return response.data;
        }
      })
      .catch((error) => {
        if (error && error.response && error.response.data)
          throw error.response.data;
      });
  },

  // forgotpassword
  forgotPassword: async function (payload) {
    let TOKEN;
    await LocalStorage.getItem("dad_token").then(function (result) {
      TOKEN = result;
    });
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${TOKEN}`,
    };
    return axiosServices
      .post(API_URL + "user/forgot-password", payload, {
        headers: headers,
      })
      .then((response) => {
        if (response && response.data) {
          return response.data;
        }
      })
      .catch((error) => {
        if (error && error.response && error.response.data)
          throw error.response.data;
      });
  },

  //resetpassword
  resetPassword: async function (payload, token) {
    const headers = {
      "Content-Type": "application/json",
    };
    const url = `${API_URL}user/reset-password/${token}`;
    return axiosServices
      .post(url, payload, {
        headers: headers,
      })
      .then((response) => {
        if (response && response.data) {
          return response.data;
        }
      })
      .catch((error) => {
        if (error && error.response && error.response.data)
          throw error.response.data;
      });
  },

  getAllBadge: async function () {
    let TOKEN;
    await LocalStorage.getItem("dad_token").then(function (result) {
      TOKEN = result;
    });
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${TOKEN}`,
    };
    return axiosServices
      .get(API_URL + "badge/get-badge", {
        headers: headers,
      })
      .then((response) => {
        if (response && response.data) {
          return response.data;
        }
      })
      .catch((error) => {
        if (error && error.response && error.response.data)
          throw error.response.data;
      });
  },

  editBadge: async function (payload) {
    let TOKEN;
    await LocalStorage.getItem("dad_token").then(function (result) {
      TOKEN = result;
    });
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${TOKEN}`,
    };
    console.log("Headersss->>>>", headers);
    // const headers = {}
    // headers['accept-language'] = 'en'
    // headers['Authorization'] = TOKEN
    // "Authorization" = `Bearer ${TOKEN}`
    return axiosServices
      .put(API_URL + "badge/update-badge", payload, {
        headers: headers,
      })
      .then((response) => {
        if (response && response.data) {
          return response.data;
        }
      })
      .catch((error) => {
        if (error && error.response && error.response.data)
          throw error.response.data;
      });
  },

  // For getting reported users list
  getReportedUsers: async function (payload) {
    let TOKEN;
    await LocalStorage.getItem("dad_token").then(function (result) {
      TOKEN = result;
    });
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${TOKEN}`,
    };
    return axiosServices
      .get(
        API_URL +
          `user/reported-users?page=${payload.page}&perPage=${
            payload.perPage
          }&search=${payload.search || ""}`,
        {
          headers: headers,
        }
      )
      .then((response) => {
        if (response && response.data) {
          return response.data;
        }
      })
      .catch((error) => {
        if (error && error.response && error.response.data)
          throw error.response.data;
      });
  },

  // For getting users list
  getUsers: async function (payload) {
    let TOKEN;
    await LocalStorage.getItem("dad_token").then(function (result) {
      TOKEN = result;
    });
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${TOKEN}`,
    };
    return axiosServices
      .get(
        API_URL +
          `cms/users-list?page=${payload.page}&perPage=${
            payload.perPage
          }&search=${payload.search || ""}`,
        {
          headers: headers,
        }
      )
      .then((response) => {
        if (response && response.data) {
          return response.data;
        }
      })
      .catch((error) => {
        if (error && error.response && error.response.data)
          throw error.response.data;
      });
  },

  // For getting user detail
  getUserDetail: async function (payload) {
    let TOKEN;
    await LocalStorage.getItem("dad_token").then(function (result) {
      TOKEN = result;
    });
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${TOKEN}`,
    };

    const url = `${API_URL}user/account/${payload}`;

    return axiosServices
      .get(url, { headers: headers })
      .then((response) => {
        if (response && response.data) {
          return response.data;
        }
      })
      .catch((error) => {
        if (error && error.response && error.response.data)
          throw error.response.data;
      });
  },

  // For blocking user from block user management
  blockUnblockUser: async function (payload) {
    let TOKEN;
    await LocalStorage.getItem("dad_token").then(function (result) {
      TOKEN = result;
    });
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${TOKEN}`,
    };
    return axiosServices
      .post(API_URL + "cms/account-block-unblock", payload, {
        headers: headers,
      })
      .then((response) => {
        if (response && response.status) {
          return response.status;
        }
      })
      .catch((error) => {
        if (error && error.response && error.response.status)
          throw error.response.status;
      });
  },

  // For getting CMS management
  getCmsManagementData: async function () {
    let TOKEN;
    await LocalStorage.getItem("dad_token").then(function (result) {
      TOKEN = result;
    });
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${TOKEN}`,
    };
    return axiosServices
      .get(API_URL + "cms/get-cms", {
        headers: headers,
      })
      .then((response) => {
        if (response && response.data) {
          console.log("hello---------", response.data);
          return response.data;
        }
      })
      .catch((error) => {
        if (error && error.response && error.response.data)
          throw error.response.data;
      });
  },

  // For updating CMS management
  updateCmsManagementData: async function (payload) {
    let TOKEN;
    await LocalStorage.getItem("dad_token").then(function (result) {
      TOKEN = result;
    });
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${TOKEN}`,
    };
    return axiosServices
      .patch(API_URL + "cms/update-cms", payload, {
        headers: headers,
      })
      .then((response) => {
        if (response && response.data) {
          return response.data;
        }
      })
      .catch((error) => {
        if (error && error.response && error.response.data)
          throw error.response.data;
      });
  },

  //get all faq data
  getFaqData: async function (payload) {
    let TOKEN;
    await LocalStorage.getItem("dad_token").then(function (result) {
      TOKEN = result;
    });
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${TOKEN}`,
    };
    return axiosServices
      .get(
        API_URL +
          `cms/faq?page=${payload.page}&perPage=${payload.perPage}&search=${
            payload.search || ""
          }`,
        {
          headers: headers,
        }
      )
      .then((response) => {
        if (response && response.data) {
          return response.data;
        }
      })
      .catch((error) => {
        if (error && error.response && error.response.data)
          throw error.response.data;
      });
  },

  // Add/Update FAQ
  addOrEditFaq: async function (payload) {
    let TOKEN;
    await LocalStorage.getItem("dad_token").then(function (result) {
      TOKEN = result;
    });
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${TOKEN}`,
    };
    return axiosServices
      .patch(API_URL + "cms/faq", payload, {
        headers: headers,
      })
      .then((response) => {
        if (response && response.data) {
          return response.data;
        }
      })
      .catch((error) => {
        if (error && error.response && error.response.data)
          throw error.response.data;
      });
  },

  // Delete FAQ
  deleteFaq: async function (payload) {
    let TOKEN;
    await LocalStorage.getItem("dad_token").then(function (result) {
      TOKEN = result;
    });
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${TOKEN}`,
    };

    const url = `${API_URL}cms/faq/${payload}`;

    return axiosServices
      .delete(url, { headers: headers })
      .then((response) => {
        if (response && response.data) {
          return response.data;
        }
      })
      .catch((error) => {
        if (error && error.response && error.response.data)
          throw error.response.data;
      });
  },

  // For getting CMS settings
  getAllSettings: async function () {
    let TOKEN;
    await LocalStorage.getItem("dad_token").then(function (result) {
      TOKEN = result;
    });
    const headers = {};
    headers["accept-language"] = "en";
    headers["Authorization"] = TOKEN;
    return axiosServices
      .post(
        API_URL + "/api/v1/cms/setting/get-all-settings",
        {},
        {
          headers: headers,
        }
      )
      .then((response) => {
        if (response && response.data) {
          return response.data;
        }
      })
      .catch((error) => {
        if (error && error.response && error.response.data)
          throw error.response.data;
      });
  },

  // For updating CMS settings
  updateSettings: async function (payload) {
    let TOKEN;
    await LocalStorage.getItem("dad_token").then(function (result) {
      TOKEN = result;
    });
    const headers = {};
    headers["accept-language"] = "en";
    headers["Authorization"] = TOKEN;
    return axiosServices
      .post(API_URL + "/api/v1/cms/setting/add-edit-settings", payload, {
        headers: headers,
      })
      .then((response) => {
        if (response && response.data) {
          return response.data;
        }
      })
      .catch((error) => {
        if (error && error.response && error.response.data)
          throw error.response.data;
      });
  },

  // Add Subscription
  addSubscription: async function (payload) {
    let TOKEN;
    await LocalStorage.getItem("dad_token").then(function (result) {
      TOKEN = result;
    });
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${TOKEN}`,
    };
    return axiosServices
      .post(API_URL + "cms/create-subscription", payload, {
        headers: headers,
      })
      .then((response) => {
        if (response && response.data) {
          return response.data;
        }
      })
      .catch((error) => {
        if (error && error.response && error.response.data)
          throw error.response.data;
      });
  },

  // Get All Subscription
  getAllSubscription: async function (payload) {
    let TOKEN;
    await LocalStorage.getItem("dad_token").then(function (result) {
      TOKEN = result;
    });
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${TOKEN}`,
    };
    return axiosServices
      .get(
        API_URL +
          `cms/subscription-cms?page=${payload.page}&perPage=${payload.perPage}`,
        {
          headers: headers,
        }
      )
      .then((response) => {
        if (response && response.data) {
          return response.data;
        }
      })
      .catch((error) => {
        if (error && error.response && error.response.data)
          throw error.response.data;
      });
  },

  // Get One Subscription
  getSubscription: async function (payload) {
    let TOKEN;
    await LocalStorage.getItem("dad_token").then(function (result) {
      TOKEN = result;
    });
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${TOKEN}`,
    };
    return axiosServices
      .get(API_URL + `cms/subscription/${payload.subscriptionId}`, {
        headers: headers,
      })
      .then((response) => {
        if (response && response.data) {
          return response.data;
        }
      })
      .catch((error) => {
        if (error && error.response && error.response.data)
          throw error.response.data;
      });
  },

  // Edit Subscription
  editSubscription: async function (payload) {
    let TOKEN;
    await LocalStorage.getItem("dad_token").then(function (result) {
      TOKEN = result;
    });
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${TOKEN}`,
    };
    return axiosServices
      .put(API_URL + `cms/update-subscription`, payload, {
        headers: headers,
      })
      .then((response) => {
        if (response && response.data) {
          return response.data;
        }
      })
      .catch((error) => {
        if (error && error.response && error.response.data)
          throw error.response.data;
      });
  },

  // Delete Subscription
  deleteSubscription: async function (payload) {
    let TOKEN;
    await LocalStorage.getItem("dad_token").then(function (result) {
      TOKEN = result;
    });
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${TOKEN}`,
    };
    return axiosServices
      .delete(API_URL + `cms/subscription/${payload.subscriptionId}`, {
        headers: headers,
      })
      .then((response) => {
        if (response && response.data) {
          return response.data;
        }
      })
      .catch((error) => {
        if (error && error.response && error.response.data)
          throw error.response.data;
      });
  },
};

export default ApiUtils;
