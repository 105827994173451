import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import "antd/dist/antd.css";
// material icons
import "material-icons/iconfont/material-icons.css";
import "bootstrap/dist/css/bootstrap.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import React from "react";
import ReactDOM from "react-dom";

import { Provider } from "react-redux";
import { ConfigProvider } from "./contexts/ConfigContext";

import "./index.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import store from "./store";

ReactDOM.render(
  <Provider store={store}>
    <ConfigProvider>
      <App />
    </ConfigProvider>
    <ToastContainer autoClose={5000} />
  </Provider>,
  document.getElementById("root")
);

reportWebVitals();
