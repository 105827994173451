const menuItems = {
  items: [
    {
      id: "navigation",
      title: "Navigation",
      type: "group",
      icon: "icon-navigation",
      children: [
        {
          id: "dashboard",
          title: "Dashboard",
          type: "item",
          url: "/app/dashboard",
          icon: "feather icon-home",
        },
        {
          id: "badge Management",
          title: "Badge Management",
          type: "item",
          url: "/app/badge-management",
          icon: "feather icon-tag",
        },
        {
          id: "user-management",
          title: "User Management",
          type: "item",
          url: "/app/user-management",
          icon: "feather icon-users",
        },
        {
          id: "reported-management",
          title: "Reported Users",
          type: "item",
          url: "/app/report-user-management",
          icon: "feather icon-user-x",
        },
        {
          id: "subscription-management",
          title: "Subscription Management",
          type: "item",
          url: "/app/subscription-management",
          icon: "feather icon-user-check",
        },
        {
          id: "cms-management",
          title: "CMS Management",
          type: "collapse",
          icon: "feather icon-menu",
          children: [
            {
              id: "terms-and-conditions",
              title: "Terms & Conditions",
              type: "item",
              url: "/app/cmsManagement/termsAndConditions",
            },
            {
              id: "privacy-policy",
              title: "Privacy Policy",
              type: "item",
              url: "/app/cmsManagement/privatePolicy",
            },
            // {
            //   id: "about-us",
            //   title: "About us",
            //   type: "item",
            //   url: "/app/cmsManagement/aboutUs",
            // },
            {
              id: "faq",
              title: "FAQ",
              type: "item",
              url: "/app/cmsManagement/faq",
            },
          ],
        },
      ],
    },
  ],
};

export default menuItems;
