import React, { Suspense, Fragment, lazy } from "react";
import { Switch, Redirect, Route } from "react-router-dom";
import Loader from "./components/Loader/Loader";
import AdminLayout from "./layouts/AdminLayout";
import { BASE_URL } from "./config/constant";

export const renderRoutes = (routes = []) => (
  <Suspense fallback={<Loader />}>
    <Switch>
      {routes.map((route, i) => {
        const Layout = route.layout || Fragment;
        const Component = route.component;

        return (
          <Route
            key={i}
            path={route.path}
            exact={route.exact}
            render={(props) => (
              <Layout>
                {route.routes ? (
                  renderRoutes(route.routes)
                ) : (
                  <Component {...props} />
                )}
              </Layout>
            )}
          />
        );
      })}
    </Switch>
  </Suspense>
);

const routes = [
  {
    exact: true,
    path: "/auth/signin-1",
    component: lazy(() => import("./views/auth/signin/SignIn1")),
  },
  {
    exact: true,
    path: "/auth/signup-1",
    component: lazy(() => import("./views/auth/signup/SignUp1")),
  },
  {
    exact: true,
    path: "/auth/forgotPassword",
    component: lazy(() => import("./views/auth/forgotPassword/ForgotPassword")),
  },
  {
    exact: true,
    path: "/auth/reset-password/:id",
    component: lazy(() => import("./views/auth/resetPassword/ResetPassword")),
  },
  {
    path: "*",
    layout: AdminLayout,
    routes: [
      {
        exact: true,
        path: "/app/dashboard",
        component: lazy(() => import("./views/dashboard/DashDefault")),
      },
      {
        exact: true,
        path: "/app/badge-management",
        component: lazy(() =>
          import("./views/BadgeManagement/BadgeManagement")
        ),
      },
      {
        exact: true,
        path: "/app/user-management",
        component: lazy(() => import("./views/userManagement/UserManagement")),
      },
      {
        exact: true,
        path: "/app/user-management/userDetail",
        component: lazy(() => import("./views/userManagement/UserDetail")),
      },
      {
        exact: true,
        path: "/app/report-user-management",
        component: lazy(() =>
          import("./views/ReportedUserManagement/ReportedUserManagement")
        ),
      },
      {
        exact: true,
        path: "/app/report-user-management/report-user-action",
        component: lazy(() =>
          import("./views/ReportedUserManagement/ReportedUserAction")
        ),
      },
      {
        exact: true,
        path: "/app/subscription-management",
        component: lazy(() =>
          import("./views/SubscriptionManagement/SubscriptionManagement")
        ),
      },
      {
        exact: true,
        path: "/app/cmsManagement/termsAndConditions",
        component: lazy(() =>
          import("./views/cmsManagement/TermsAndConditions/TermsAndConditions")
        ),
      },
      {
        exact: true,
        path: "/app/cmsManagement/privatePolicy",
        component: lazy(() =>
          import("./views/cmsManagement/PrivacyPolicy/PrivacyPolicy")
        ),
      },
      {
        exact: true,
        path: "/app/cmsManagement/aboutUs",
        component: lazy(() =>
          import("./views/cmsManagement/TermsAndConditions/AboutUs")
        ),
      },
      {
        exact: true,
        path: "/app/cmsManagement/faq",
        component: lazy(() => import("./views/cmsManagement/FAQ/Faq")),
      },
      {
        exact: true,
        path: "/app/adminProfile/default",
        component: lazy(() => import("./views/adminProfile/adminProfile")),
      },
      {
        exact: true,
        path: "/app/cmsManagement/contactUs",
        component: lazy(() =>
          import("./views/cmsManagement/TermsAndConditions/ContactUs")
        ),
      },
      {
        exact: true,
        path: "/basic/button",
        component: lazy(() => import("./views/ui-elements/basic/BasicButton")),
      },
      {
        exact: true,
        path: "/basic/badges",
        component: lazy(() => import("./views/ui-elements/basic/BasicBadges")),
      },
      {
        exact: true,
        path: "/basic/breadcrumb",
        component: lazy(() =>
          import("./views/ui-elements/basic/BasicBreadcrumb")
        ),
      },
      {
        exact: true,
        path: "/basic/pagination",
        component: lazy(() =>
          import("./views/ui-elements/basic/BasicPagination")
        ),
      },
      {
        exact: true,
        path: "/basic/collapse",
        component: lazy(() =>
          import("./views/ui-elements/basic/BasicCollapse")
        ),
      },
      {
        exact: true,
        path: "/basic/tabs-pills",
        component: lazy(() =>
          import("./views/ui-elements/basic/BasicTabsPills")
        ),
      },
      {
        exact: true,
        path: "/basic/typography",
        component: lazy(() =>
          import("./views/ui-elements/basic/BasicTypography")
        ),
      },
      {
        path: "*",
        exact: true,
        component: () => <Redirect to={BASE_URL} />,
      },
    ],
  },
];

export default routes;
