import { LoadingOutlined } from '@ant-design/icons'
import { Spin } from 'antd'

const AntdSpinner = () => {
  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 40,
        color: '#F15C4D',
      }}
      spin
    />
  )

  return (
    <span className='loader-class'>
      <Spin indicator={antIcon} />
    </span>
  )
}

export default AntdSpinner
