import React, { useState, createContext } from 'react'
import { BrowserRouter as Router } from 'react-router-dom'

import { FirebaseProvider } from './contexts/FirebaseContext'

import routes, { renderRoutes } from './routes'
import { BASENAME } from './config/constant'

const PaginationContext = createContext()

const App = () => {
  const [page, setPage] = useState(1)

  return (
    <React.Fragment>
      <PaginationContext.Provider value={{ page, setPage }}>
        <Router basename={BASENAME}>
          <FirebaseProvider>{renderRoutes(routes)}</FirebaseProvider>
        </Router>
      </PaginationContext.Provider>
    </React.Fragment>
  )
}

export default App
export { PaginationContext }
