import axios from 'axios'
const axiosServices = axios.create()

axiosServices.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (error.response.status === 401 && !window?.location?.href?.includes('auth/signin-1')) {
      localStorage.removeItem('dad_token')
      window.location = '/auth/signin-1'
    }
    return Promise.reject(error || 'Wrong Services')
  }
)

export default axiosServices
