import React, { useContext, useState } from "react";
import { ListGroup, Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import ChatList from "./ChatList";
import { ConfigContext } from "../../../../contexts/ConfigContext";
import useAuth from "../../../../hooks/useAuth";
import avatar2 from "../../../../assets/images/user/avtar.jpg";
import { useHistory } from "react-router";
import ChangePasswordModal from "./ChangePassword/ChangePasswordModal";

const NavRight = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  const configContext = useContext(ConfigContext);
  const { logout } = useAuth();
  const { rtlLayout } = configContext.state;

  const history = useHistory();

  const [listOpen, setListOpen] = useState(false);
  const [changePasswordModal, setChangePasswordModal] = useState(false);

  const handleLogout = async () => {
    try {
      //handleClose();
      await logout();
    } catch (err) {
      console.error(err);
    }
  };
  const handleEditUserProfile = () => {
    history.push('/app/adminProfile/default')
}

  return (
    <React.Fragment>
      <ListGroup
        as="ul"
        bsPrefix=" "
        className="navbar-nav ml-auto"
        id="navbar-right"
      >
        {/* <ListGroup.Item as='li' bsPrefix=' '>
          <Dropdown alignRight={!rtlLayout}>
            <Dropdown.Toggle
              as={Link}
              variant='link'
              to='#'
              id='dropdown-basic'
            >
              <i className='feather icon-bell icon' />
            </Dropdown.Toggle>
            <Dropdown.Menu
              alignRight
              className='notification notification-scroll'
            >
              <div className='noti-head'>
                <h6 className='d-inline-block m-b-0'>Notifications</h6>
                <div className='float-right'>
                  <Link to='#' className='m-r-10'>
                    mark as read
                  </Link>
                  <Link to='#'>clear all</Link>
                </div>
              </div>
              <PerfectScrollbar>
                <ListGroup
                  as='ul'
                  bsPrefix=' '
                  variant='flush'
                  className='noti-body'
                >
                  <ListGroup.Item as='li' bsPrefix=' ' className='n-title'>
                    <p className='m-b-0'>NEW</p>
                  </ListGroup.Item>
                  <ListGroup.Item as='li' bsPrefix=' ' className='notification'>
                    <Media>
                      <img
                        className='img-radius'
                        src={avatar1}
                        alt='Generic placeholder'
                      />
                      <Media.Body>
                        <p>
                          <strong>John Doe</strong>
                          <span className='n-time text-muted'>
                            <i className='icon feather icon-clock m-r-10' />
                            30 min
                          </span>
                        </p>
                        <p>New ticket Added</p>
                      </Media.Body>
                    </Media>
                  </ListGroup.Item>
                  <ListGroup.Item as='li' bsPrefix=' ' className='n-title'>
                    <p className='m-b-0'>EARLIER</p>
                  </ListGroup.Item>
                  <ListGroup.Item as='li' bsPrefix=' ' className='notification'>
                    <Media>
                      <img
                        className='img-radius'
                        src={avatar2}
                        alt='Generic placeholder'
                      />
                      <Media.Body>
                        <p>
                          <strong>Joseph William</strong>
                          <span className='n-time text-muted'>
                            <i className='icon feather icon-clock m-r-10' />
                            30 min
                          </span>
                        </p>
                        <p>Purchase New Theme and make payment</p>
                      </Media.Body>
                    </Media>
                  </ListGroup.Item>
                  <ListGroup.Item as='li' bsPrefix=' ' className='notification'>
                    <Media>
                      <img
                        className='img-radius'
                        src={avatar3}
                        alt='Generic placeholder'
                      />
                      <Media.Body>
                        <p>
                          <strong>Sara Soudein</strong>
                          <span className='n-time text-muted'>
                            <i className='icon feather icon-clock m-r-10' />
                            30 min
                          </span>
                        </p>
                        <p>currently login</p>
                      </Media.Body>
                    </Media>
                  </ListGroup.Item>
                  <ListGroup.Item as='li' bsPrefix=' ' className='notification'>
                    <Media>
                      <img
                        className='img-radius'
                        src={avatar4}
                        alt='Generic placeholder'
                      />
                      <Media.Body>
                        <p>
                          <strong>Suzen</strong>
                          <span className='n-time text-muted'>
                            <i className='icon feather icon-clock m-r-10' />
                            yesterday
                          </span>
                        </p>
                        <p>Purchase New Theme and make payment</p>
                      </Media.Body>
                    </Media>
                  </ListGroup.Item>
                </ListGroup>
              </PerfectScrollbar>
              <div className='noti-footer'>
                <Link to='#'>show all</Link>
              </div>
            </Dropdown.Menu>
          </Dropdown>
        </ListGroup.Item> */}

        {/* <ListGroup.Item as='li' bsPrefix=' '>
          <Dropdown>
            <Dropdown.Toggle
              as={Link}
              variant='link'
              to='#'
              className='displayChatbox'
              onClick={() => setListOpen(true)}
            >
              <i className='icon feather icon-mail' />
            </Dropdown.Toggle>
          </Dropdown>
        </ListGroup.Item> */}
        <ListGroup.Item as="li" bsPrefix=" " action="">
          <Dropdown alignRight={!rtlLayout} className="drp-user">
            <Dropdown.Toggle
              as={Link}
              variant="link"
              to="#"
              id="dropdown-basic"
            >
              {/* <b className='mr-2'>DAD CMS</b> */}
              <i className="icon feather icon-settings" />
            </Dropdown.Toggle>
            <Dropdown.Menu alignRight className="profile-notification">
              {/* <div className="pro-head">
                <img src={avatar1} className='img-radius' alt='User Profile' />
                <span>
                  <small>{user && user.name}</small>
                </span>
                <Link
                  to="#"
                  className="dud-logout"
                  title="Edit Profile"
                  onClick={handleEditUserProfile}
                >
                  <i className="feather icon-edit" />
                </Link>
              </div> */}
              <div
                className="pro-head"
                onClick={(e) => {
                  e.currentTarget.parentElement.classList.toggle("show");
                  e.currentTarget.parentElement.parentElement.classList.toggle(
                    "show"
                  );
                }}
              >
                <img src={user?.profileImage} className="img-radius" alt="User Profile" />
                <span>
                  {user && user.name}{" "}
                </span>
                <Link
                  to="#"
                  className="dud-logout"
                  title="Edit Profile"
                  onClick={handleEditUserProfile}
                >
                  <i className="feather icon-edit" />
                </Link>
              </div>
              <Link to="#" className="dud-logout" title="Logout">
                <i className="fa-solid fa-right-from-bracket" />
              </Link>
              {/* </div> */}
              <ListGroup
                as="ul"
                bsPrefix=" "
                variant="flush"
                className="pro-body"
              >
                <ListGroup.Item
                  as="li"
                  bsPrefix=" "
                  onClick={(e) => {
                    e.currentTarget.parentElement.parentElement.classList.toggle(
                      "show"
                    );
                    e.currentTarget.parentElement.parentElement.parentElement.classList.toggle(
                      "show"
                    );
                  }}
                >
                  <Link
                    to="#"
                    className="dropdown-item"
                    onClick={() => {
                      setChangePasswordModal(true);
                    }}
                  >
                    <i className="feather icon-lock" /> Change Password
                  </Link>
                </ListGroup.Item>
                <ListGroup.Item as="li" bsPrefix=" ">
                  <Link
                    to="#"
                    className="dropdown-item"
                    onClick={() => {
                      localStorage.removeItem("user");
                      localStorage.removeItem("dad_token");
                      history.push("/");
                    }}
                  >
                    <i className="feather icon-log-out" /> Logout
                  </Link>
                </ListGroup.Item>
              </ListGroup>
            </Dropdown.Menu>
          </Dropdown>
        </ListGroup.Item>
      </ListGroup>
      <ChatList listOpen={listOpen} closed={() => setListOpen(false)} />

      {changePasswordModal && (
        <ChangePasswordModal
          changePasswordModal={changePasswordModal}
          setChangePasswordModal={setChangePasswordModal}
        />
      )}
    </React.Fragment>
  );
};

export default NavRight;
