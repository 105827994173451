const chat = [
  // {
  //   friend_id: 1,
  //   friend_photo: 'avatar-1.jpg',
  //   messages: [
  //     {
  //       type: 1,
  //       msg: "I'm just looking around. Will you tell me something about yourself?",
  //       time: '8:20 a.m'
  //     },
  //     {
  //       type: 0,
  //       msg: 'Ohh! very nice',
  //       time: '8:22 a.m'
  //     },
  //     {
  //       type: 1,
  //       msg: 'can you come with me?',
  //       time: '8:22 a.m'
  //     }
  //   ]
  // },
  // {
  //   friend_id: 2,
  //   friend_photo: 'avatar-2.jpg',
  //   messages: [
  //     {
  //       type: 1,
  //       msg: 'Hiii!!! Good Morning',
  //       time: '6:48 a.m'
  //     },
  //     {
  //       type: 0,
  //       msg: 'Hello, Very Good Morning',
  //       time: '6:50 a.m'
  //     },
  //     {
  //       type: 0,
  //       msg: 'How are You?',
  //       time: '8:50 a.m'
  //     },
  //     {
  //       type: 1,
  //       msg: 'Fine, What do you do?',
  //       time: '8:51 a.m'
  //     }
  //   ]
  // },
  // {
  //   friend_id: 4,
  //   friend_photo: 'avatar-1.jpg',
  //   messages: [
  //     {
  //       type: 1,
  //       msg: 'Hiii!!! Good Morning',
  //       time: '6:48 a.m'
  //     },
  //     {
  //       type: 0,
  //       msg: 'Hello, Very Good Morning',
  //       time: '6:50 a.m'
  //     },
  //     {
  //       type: 0,
  //       msg: 'How are You?',
  //       time: '8:50 a.m'
  //     },
  //     {
  //       type: 1,
  //       msg: 'Fine, What do you do?',
  //       time: '8:51 a.m'
  //     }
  //   ]
  // },
  // {
  //   friend_id: 5,
  //   friend_photo: 'avatar-4.jpg',
  //   messages: [
  //     {
  //       type: 1,
  //       msg: "I'm just looking around. Will you tell me something about yourself?",
  //       time: '8:20 a.m'
  //     },
  //     {
  //       type: 0,
  //       msg: 'Ohh! very nice',
  //       time: '8:22 a.m'
  //     },
  //     {
  //       type: 1,
  //       msg: 'can you come with me?',
  //       time: '8:22 a.m'
  //     }
  //   ]
  // },
  // {
  //   friend_id: 6,
  //   friend_photo: 'avatar-1.jpg',
  //   messages: [
  //     {
  //       type: 1,
  //       msg: "I'm just looking around. Will you tell me something about yourself?",
  //       time: '8:20 a.m'
  //     },
  //     {
  //       type: 0,
  //       msg: 'Ohh! very nice',
  //       time: '8:22 a.m'
  //     },
  //     {
  //       type: 1,
  //       msg: 'can you come with me?',
  //       time: '8:22 a.m'
  //     },
  //     {
  //       type: 1,
  //       msg: "I'm just looking around. Will you tell me something about yourself?",
  //       time: '8:20 a.m'
  //     },
  //     {
  //       type: 0,
  //       msg: 'Ohh! very nice',
  //       time: '8:22 a.m'
  //     },
  //     {
  //       type: 1,
  //       msg: 'can you come with me?',
  //       time: '8:22 a.m'
  //     },
  //     {
  //       type: 1,
  //       msg: "I'm just looking around. Will you tell me something about yourself?",
  //       time: '8:20 a.m'
  //     },
  //     {
  //       type: 0,
  //       msg: 'Ohh! very nice',
  //       time: '8:22 a.m'
  //     },
  //     {
  //       type: 1,
  //       msg: 'can you come with me?',
  //       time: '8:22 a.m'
  //     },
  //     {
  //       type: 1,
  //       msg: "I'm just looking around. Will you tell me something about yourself?",
  //       time: '8:20 a.m'
  //     },
  //     {
  //       type: 0,
  //       msg: 'Ohh! very nice',
  //       time: '8:22 a.m'
  //     },
  //     {
  //       type: 1,
  //       msg: 'can you come with me?',
  //       time: '8:22 a.m'
  //     },
  //     {
  //       type: 1,
  //       msg: "I'm just looking around. Will you tell me something about yourself?",
  //       time: '8:20 a.m'
  //     },
  //     {
  //       type: 0,
  //       msg: 'Ohh! very nice',
  //       time: '8:22 a.m'
  //     },
  //     {
  //       type: 1,
  //       msg: 'can you come with me?',
  //       time: '8:22 a.m'
  //     }
  //   ]
  // },
  // {
  //   friend_id: 7,
  //   friend_photo: 'avatar-2.jpg',
  //   messages: [
  //     {
  //       type: 1,
  //       msg: 'Hiii!!! Good Morning',
  //       time: '6:48 a.m'
  //     },
  //     {
  //       type: 0,
  //       msg: 'Hello, Very Good Morning',
  //       time: '6:50 a.m'
  //     },
  //     {
  //       type: 0,
  //       msg: 'How are You?',
  //       time: '8:50 a.m'
  //     },
  //     {
  //       type: 1,
  //       msg: 'Fine, What do you do?',
  //       time: '8:51 a.m'
  //     }
  //   ]
  // },
  // {
  //   friend_id: 8,
  //   friend_photo: 'avatar-3.jpg',
  //   messages: [
  //     {
  //       type: 1,
  //       msg: "I'm just looking around. Will you tell me something about yourself?",
  //       time: '8:20 a.m'
  //     },
  //     {
  //       type: 0,
  //       msg: 'Ohh! very nice',
  //       time: '8:22 a.m'
  //     },
  //     {
  //       type: 1,
  //       msg: 'can you come with me?',
  //       time: '8:22 a.m'
  //     }
  //   ]
  // },
  // {
  //   friend_id: 9,
  //   friend_photo: 'avatar-1.jpg',
  //   messages: [
  //     {
  //       type: 1,
  //       msg: 'Hiii!!! Good Morning',
  //       time: '6:48 a.m'
  //     },
  //     {
  //       type: 0,
  //       msg: 'Hello, Very Good Morning',
  //       time: '6:50 a.m'
  //     },
  //     {
  //       type: 0,
  //       msg: 'How are You?',
  //       time: '8:50 a.m'
  //     },
  //     {
  //       type: 1,
  //       msg: 'Fine, What do you do?',
  //       time: '8:51 a.m'
  //     }
  //   ]
  // },
  // {
  //   friend_id: 10,
  //   friend_photo: 'avatar-4.jpg',
  //   messages: [
  //     {
  //       type: 1,
  //       msg: "I'm just looking around. Will you tell me something about yourself?",
  //       time: '8:20 a.m'
  //     },
  //     {
  //       type: 0,
  //       msg: 'Ohh! very nice',
  //       time: '8:22 a.m'
  //     },
  //     {
  //       type: 1,
  //       msg: 'can you come with me?',
  //       time: '8:22 a.m'
  //     }
  //   ]
  // },
  // {
  //   friend_id: 11,
  //   friend_photo: 'avatar-1.jpg',
  //   messages: [
  //     {
  //       type: 1,
  //       msg: "I'm just looking around. Will you tell me something about yourself?",
  //       time: '8:20 a.m'
  //     },
  //     {
  //       type: 0,
  //       msg: 'Ohh! very nice',
  //       time: '8:22 a.m'
  //     },
  //     {
  //       type: 1,
  //       msg: 'can you come with me?',
  //       time: '8:22 a.m'
  //     }
  //   ]
  // },
  // {
  //   friend_id: 12,
  //   friend_photo: 'avatar-2.jpg',
  //   messages: [
  //     {
  //       type: 1,
  //       msg: 'Hiii!!! Good Morning',
  //       time: '6:48 a.m'
  //     },
  //     {
  //       type: 0,
  //       msg: 'Hello, Very Good Morning',
  //       time: '6:50 a.m'
  //     },
  //     {
  //       type: 0,
  //       msg: 'How are You?',
  //       time: '8:50 a.m'
  //     },
  //     {
  //       type: 1,
  //       msg: 'Fine, What do you do?',
  //       time: '8:51 a.m'
  //     }
  //   ]
  // },
  // {
  //   friend_id: 13,
  //   friend_photo: 'avatar-3.jpg',
  //   messages: [
  //     {
  //       type: 1,
  //       msg: "I'm just looking around. Will you tell me something about yourself?",
  //       time: '8:20 a.m'
  //     },
  //     {
  //       type: 0,
  //       msg: 'Ohh! very nice',
  //       time: '8:22 a.m'
  //     },
  //     {
  //       type: 1,
  //       msg: 'can you come with me?',
  //       time: '8:22 a.m'
  //     }
  //   ]
  // },
  // {
  //   friend_id: 14,
  //   friend_photo: 'avatar-1.jpg',
  //   messages: [
  //     {
  //       type: 1,
  //       msg: 'Hiii!!! Good Morning',
  //       time: '6:48 a.m'
  //     },
  //     {
  //       type: 0,
  //       msg: 'Hello, Very Good Morning',
  //       time: '6:50 a.m'
  //     },
  //     {
  //       type: 0,
  //       msg: 'How are You?',
  //       time: '8:50 a.m'
  //     },
  //     {
  //       type: 1,
  //       msg: 'Fine, What do you do?',
  //       time: '8:51 a.m'
  //     }
  //   ]
  // },
  // {
  //   friend_id: 15,
  //   friend_photo: 'avatar-4.jpg',
  //   messages: [
  //     {
  //       type: 1,
  //       msg: "I'm just looking around. Will you tell me something about yourself?",
  //       time: '8:20 a.m'
  //     },
  //     {
  //       type: 0,
  //       msg: 'Ohh! very nice',
  //       time: '8:22 a.m'
  //     },
  //     {
  //       type: 1,
  //       msg: 'can you come with me?',
  //       time: '8:22 a.m'
  //     }
  //   ]
  // },
  // {
  //   friend_id: 16,
  //   friend_photo: 'avatar-1.jpg',
  //   messages: [
  //     {
  //       type: 1,
  //       msg: "I'm just looking around. Will you tell me something about yourself?",
  //       time: '8:20 a.m'
  //     },
  //     {
  //       type: 0,
  //       msg: 'Ohh! very nice',
  //       time: '8:22 a.m'
  //     },
  //     {
  //       type: 1,
  //       msg: 'can you come with me?',
  //       time: '8:22 a.m'
  //     }
  //   ]
  // },
  // {
  //   friend_id: 17,
  //   friend_photo: 'avatar-2.jpg',
  //   messages: [
  //     {
  //       type: 1,
  //       msg: 'Hiii!!! Good Morning',
  //       time: '6:48 a.m'
  //     },
  //     {
  //       type: 0,
  //       msg: 'Hello, Very Good Morning',
  //       time: '6:50 a.m'
  //     },
  //     {
  //       type: 0,
  //       msg: 'How are You?',
  //       time: '8:50 a.m'
  //     },
  //     {
  //       type: 1,
  //       msg: 'Fine, What do you do?',
  //       time: '8:51 a.m'
  //     }
  //   ]
  // },
  // {
  //   friend_id: 18,
  //   friend_photo: 'avatar-3.jpg',
  //   messages: [
  //     {
  //       type: 1,
  //       msg: "I'm just looking around. Will you tell me something about yourself?",
  //       time: '8:20 a.m'
  //     },
  //     {
  //       type: 0,
  //       msg: 'Ohh! very nice',
  //       time: '8:22 a.m'
  //     },
  //     {
  //       type: 1,
  //       msg: 'can you come with me?',
  //       time: '8:22 a.m'
  //     }
  //   ]
  // },
  // {
  //   friend_id: 19,
  //   friend_photo: 'avatar-1.jpg',
  //   messages: [
  //     {
  //       type: 1,
  //       msg: 'Hiii!!! Good Morning',
  //       time: '6:48 a.m'
  //     },
  //     {
  //       type: 0,
  //       msg: 'Hello, Very Good Morning',
  //       time: '6:50 a.m'
  //     },
  //     {
  //       type: 0,
  //       msg: 'How are You?',
  //       time: '8:50 a.m'
  //     },
  //     {
  //       type: 1,
  //       msg: 'Fine, What do you do?',
  //       time: '8:51 a.m'
  //     }
  //   ]
  // },
  // {
  //   friend_id: 20,
  //   friend_photo: 'avatar-2.jpg',
  //   messages: [
  //     {
  //       type: 1,
  //       msg: "I'm just looking around. Will you tell me something about yourself?",
  //       time: '8:20 a.m'
  //     },
  //     {
  //       type: 0,
  //       msg: 'Ohh! very nice',
  //       time: '8:22 a.m'
  //     },
  //     {
  //       type: 1,
  //       msg: 'can you come with me?',
  //       time: '8:22 a.m'
  //     }
  //   ]
  // },
  // {
  //   friend_id: 21,
  //   friend_photo: 'avatar-3.jpg',
  //   messages: [
  //     {
  //       type: 1,
  //       msg: "I'm just looking around. Will you tell me something about yourself?",
  //       time: '8:20 a.m'
  //     },
  //     {
  //       type: 0,
  //       msg: 'Ohh! very nice',
  //       time: '8:22 a.m'
  //     },
  //     {
  //       type: 1,
  //       msg: 'can you come with me?',
  //       time: '8:22 a.m'
  //     }
  //   ]
  // }
];

export default chat;
